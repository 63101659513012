import React from "react";
import moment from "moment";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { formatMoney } from "../../services/utils/helpersFunc";

const InstantLoanBorrowersInformation = ({ loanRequest, user }) => {
  return (
    <Box bg="#FFFFFF" mt="20px" borderRadius="10px">
      <Text
        color="#540A45"
        fontWeight="700"
        fontFamily="satoshiBolder"
        p="10px 20px"
        fontSize="14px"
      >
        Borrower's Information
      </Text>
      <SimpleGrid
        spacing="20px"
        minChildWidth={["100px", "70px", "50px"]}
        p="20px"
        border="1px solid #E4E8F2"
      >
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            FIRST NAME
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest.first_name}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            LAST NAME
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest.last_name}
          </Text>
        </Box>

        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            MAIDEN NAME{" "}
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {user?.middle_name || loanRequest?.maiden_name}
          </Text>
        </Box>

        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            BVN
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest.bvn}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            SEX
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest.gender}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            MARITAL STATUS
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest.marital_status}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            DATE OF BIRTH
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest?.dob}
            {/* {moment(loanRequest.dob).format("Do MMMM YYYY")} */}
          </Text>
        </Box>
      </SimpleGrid>

      <SimpleGrid
        spacing="20px"
        minChildWidth={["100px", "70px", "50px"]}
        p="20px"
        border="1px solid #E4E8F2"
      >
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            PHONE NUMBER
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest.phone}
          </Text>
        </Box>

        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            ALTERNATIVE PHONE NUMBER
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest.alternate_phone || "-"}
          </Text>
        </Box>

        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            EMAIL ADDRESS
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest.email}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            residential address
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest.address || "-"}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            NEAREST LANDMARK
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest.landmark || "-"}
          </Text>
        </Box>
      </SimpleGrid>

      <SimpleGrid
        spacing="20px"
        minChildWidth={["100px", "70px", "50px"]}
        p="20px"
        border="1px solid #E4E8F2"
      >
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            RENTED/OWNED
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest.home_ownership || "-"}
          </Text>
        </Box>

        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            loan amount requested
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest?.amount && `₦ ${formatMoney(loanRequest.amount)}`}
          </Text>
        </Box>

        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            Credit system recommended amount
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest.credit_recommended_amount
              ? `₦ ${formatMoney(
                  Number(loanRequest.credit_recommended_amount)
                )}`
              : "-"}
          </Text>
        </Box>
        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            SUBMITTED ON
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {moment(loanRequest.created_at).format("Do MMM, YYYY; h:mm a")}
          </Text>
        </Box>

        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
            textTransform="uppercase"
          >
            Purpose{" "}
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest?.purpose}
          </Text>
        </Box>

        <Box>
          <Text
            opacity="0.81px"
            fontWeight="500"
            fontSize="10px"
            color="#666666"
            lineHeight="18px"
          >
            TENURE
          </Text>
          <Text
            fontWeight="500"
            fontSize="14px"
            color="#33343D"
            lineHeight="18px"
            fontFamily="satoshiBold"
          >
            {loanRequest?.tenure} Months
          </Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default InstantLoanBorrowersInformation;
