import React, { useState, useEffect } from "react";
import Countdown from "react-countdown";
import { Box, Button } from "@chakra-ui/react";
import moment from "moment";
import { toast } from "react-toastify";
import { pauseSLA } from "../services/axios";

function RequestSLA({ loanRequestSLA }) {
  const role = localStorage.getItem("role");
  const [sla, setSla] = useState();
  const [alreadyActioned, setAlreadyAction] = useState(false);
  const [actionedTime, setActionedTime] = useState();

  console.log(role, "THE ROLE");
  console.log(sla, "THE SLA FINANA");
  console.log(alreadyActioned, "THE ACTIONED");

  const getReceivedDateByRole = () => {
    if (role === "Reporting") {
      return loanRequestSLA?.sales_received;
    } else if (role === "Finance Ops") {
      return loanRequestSLA?.finance_ops_received;
    } else if (role === "Finance Admin") {
      return loanRequestSLA?.finance_admin_received;
    } else {
      return loanRequestSLA?.admin_received;
    }
  };

  const getActionedTimeByRole = () => {
    if (role === "Reporting") {
      return loanRequestSLA?.sales_actioned;
    } else if (role === "Finance Ops") {
      return loanRequestSLA?.finance_ops_actioned;
    } else if (role === "Finance Admin") {
      return loanRequestSLA?.finance_admin_actioned;
    } else {
      return loanRequestSLA?.admin_actioned;
    }
  };

  const getAlreadyActionedByRole = () => {
    if (role === "Reporting") {
      if (loanRequestSLA?.sales_actioned) {
        setAlreadyAction(true);
      }
    }

    if (role === "Finance Ops") {
      if (loanRequestSLA?.finance_ops_actioned) {
        setAlreadyAction(true);
      }
    }

    if (role === "Finance Admin") {
      if (loanRequestSLA?.finance_admin_actioned) {
        setAlreadyAction(true);
      }
    }

    if (role === "Admin") {
      if (loanRequestSLA?.admin_actioned) {
        setAlreadyAction(true);
      }
    }
  };

  console.log(alreadyActioned, "THE ACTIONED");

  useEffect(() => {
    setSla(getReceivedDateByRole());
    getAlreadyActionedByRole();
    setActionedTime(getActionedTimeByRole());
    // eslint-disable-next-line
  }, [
    loanRequestSLA?.sales_received,
    loanRequestSLA?.finance_ops_received,
    loanRequestSLA?.finance_admin_received,
    loanRequestSLA?.admin_received,
  ]);

  const targetDate = sla ? new Date(new Date(sla).getTime() + 21600000) : null;

  const pauseSLAHandler = async () => {
    const res = await pauseSLA(loanRequestSLA?.request_id);
    if (res) {
      toast.success("SLA paused successfully");
      window.location.reload();
    }
  };

  return (
    <div>
      <Box fontWeight={"extrabold"} fontSize={"30px"}>
        {sla && !alreadyActioned && (
          <Countdown
            date={targetDate}
            overtime={true}
            style={{
              fontSize: "20px",
              fontFamily: "satoshiBold",
              color: "#5B2E4F",
              fontWeight: "500",
            }}
          />
        )}

        {alreadyActioned && (
          <span style={{ color: "#5B2E4F", fontSize: "20px" }}>
            Actioned: {moment(actionedTime).format("DD/MM/YYYY, h:mm:ss a")}
          </span>
        )}

        {role === "Reporting" && !loanRequestSLA?.sales_actioned && (
          <Button
            bg="#FF0000"
            color="white"
            fontSize="12px"
            fontWeight="800"
            fontFamily="satoshiBold"
            p="13px 35px"
            ml="20px"
            onClick={pauseSLAHandler}
          >
            PAUSE SLA
          </Button>
        )}

        {role === "Finance ops" && !loanRequestSLA?.finance_ops_actioned ? (
          <Button
            bg="#FF0000"
            color="white"
            fontSize="12px"
            fontWeight="800"
            fontFamily="satoshiBold"
            p="13px 35px"
            ml="20px"
            onClick={() => pauseSLAHandler()}
          >
            PAUSE SLA
          </Button>
        ) : (
          ""
        )}
      </Box>
    </div>
  );
}

export default RequestSLA;
