import React from "react";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { formatMoney } from "../../services/utils/helpersFunc";

const RepaymentDetails = ({ cardDetails, loanRequest }) => {
  return (
    <Box bg="#FFFFFF" mt="20px" borderRadius="10px">
      <Text
        color="#540A45"
        fontWeight="700"
        fontFamily="satoshiBolder"
        p="10px 20px"
        fontSize="14px"
      >
        Authorized Repayment Information
      </Text>

      {!loanRequest.repaymentConfig?.repayment_direct_debit_id && (
        <>
          <SimpleGrid
            spacing="20px"
            minChildWidth={["100px", "70px", "50px"]}
            p="20px"
            border="1px solid #E4E8F2"
          >
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                REPAYMENT METHOD
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {loanRequest?.repaymentConfig?.repayment_card_id
                  ? "Card"
                  : "Bank / Direct Debit"}
              </Text>
            </Box>
            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
              >
                Bank
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {loanRequest?.repaymentConfig?.repayment_bank_name}
              </Text>
            </Box>

            {loanRequest?.repaymentConfig?.repayment_card_id && (
              <Box>
                <Text
                  opacity="0.81px"
                  fontWeight="500"
                  fontSize="10px"
                  color="#666666"
                  lineHeight="18px"
                >
                  CARD TYPE
                </Text>
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  color="#33343D"
                  lineHeight="18px"
                  fontFamily="satoshiBold"
                >
                  {cardDetails?.card_type}
                </Text>
              </Box>
            )}

            {loanRequest?.repaymentConfig?.repayment_card_id ? (
              <Box>
                <Text
                  opacity="0.81px"
                  fontWeight="500"
                  fontSize="10px"
                  color="#666666"
                  lineHeight="18px"
                >
                  LAST 4 DIGITS
                </Text>
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  color="#33343D"
                  lineHeight="18px"
                  fontFamily="satoshiBold"
                >
                  **** **** {cardDetails?.last4}
                </Text>
              </Box>
            ) : (
              <Box>
                <Text
                  opacity="0.81px"
                  fontWeight="500"
                  fontSize="10px"
                  color="#666666"
                  lineHeight="18px"
                >
                  ACCOUNT NO.
                </Text>
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  color="#33343D"
                  lineHeight="18px"
                  fontFamily="satoshiBold"
                >
                  {loanRequest?.repaymentConfig?.repayment_bank_account_no}
                </Text>
              </Box>
            )}

            {loanRequest?.repaymentConfig?.repayment_card_id ? (
              <Box>
                <Text
                  opacity="0.81px"
                  fontWeight="500"
                  fontSize="10px"
                  color="#666666"
                  lineHeight="18px"
                >
                  CARD BIN
                </Text>
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  color="#33343D"
                  lineHeight="18px"
                  fontFamily="satoshiBold"
                >
                  **** **** {cardDetails?.bin}
                </Text>
              </Box>
            ) : (
              <Box>
                <Text
                  opacity="0.81px"
                  fontWeight="500"
                  fontSize="10px"
                  color="#666666"
                  lineHeight="18px"
                >
                  BANK CODE
                </Text>
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  color="#33343D"
                  lineHeight="18px"
                  fontFamily="satoshiBold"
                >
                  {loanRequest?.repaymentConfig?.repayment_bank_code}
                </Text>
              </Box>
            )}
          </SimpleGrid>

          <SimpleGrid
            spacing="20px"
            minChildWidth={["100px", "70px", "50px"]}
            p="20px"
            border="1px solid #E4E8F2"
          >
            {loanRequest?.repaymentConfig?.repayment_card_id && (
              <Box>
                <Text
                  opacity="0.81px"
                  fontWeight="500"
                  fontSize="10px"
                  color="#666666"
                  lineHeight="18px"
                >
                  EXPIRING YEAR
                </Text>
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  color="#33343D"
                  lineHeight="18px"
                  fontFamily="satoshiBold"
                >
                  {cardDetails?.exp_year}
                </Text>
              </Box>
            )}

            {loanRequest?.repaymentConfig?.repayment_card_id && (
              <Box>
                <Text
                  opacity="0.81px"
                  fontWeight="500"
                  fontSize="10px"
                  color="#666666"
                  lineHeight="18px"
                >
                  EXPIRING MONTH
                </Text>
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  color="#33343D"
                  lineHeight="18px"
                  fontFamily="satoshiBold"
                >
                  {cardDetails?.exp_month}
                </Text>
              </Box>
            )}

            {loanRequest?.repaymentConfig?.repayment_card_id && (
              <Box>
                <Text
                  opacity="0.81px"
                  fontWeight="500"
                  fontSize="10px"
                  color="#666666"
                  lineHeight="18px"
                >
                  ACCOUNT NAME
                </Text>
                <Text
                  fontWeight="500"
                  fontSize="14px"
                  color="#33343D"
                  lineHeight="18px"
                  fontFamily="satoshiBold"
                >
                  {cardDetails?.account_name}
                </Text>
              </Box>
            )}

            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
                textTransform="uppercase"
              >
                LOAN TENURE
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                {loanRequest?.tenure} Months
              </Text>
            </Box>

            <Box>
              <Text
                opacity="0.81px"
                fontWeight="500"
                fontSize="10px"
                color="#666666"
                lineHeight="18px"
                textTransform="uppercase"
              >
                APPROVED AMOUNT
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#33343D"
                lineHeight="18px"
                fontFamily="satoshiBold"
              >
                ₦{" "}
                {formatMoney(
                  Number(
                    loanRequest?.final_amount ||
                      loanRequest?.credit_recommended_amount ||
                      loanRequest?.amount
                  )
                )}
              </Text>
            </Box>
          </SimpleGrid>
        </>
      )}

      {loanRequest?.repaymentConfig?.repayment_direct_debit_id && (
        <SimpleGrid
          spacing="20px"
          minChildWidth={["100px", "70px", "50px"]}
          p="20px"
          border="1px solid #E4E8F2"
        >
          <Box>
            <Text
              opacity="0.81px"
              fontWeight="500"
              fontSize="10px"
              color="#666666"
              lineHeight="18px"
            >
              REPAYMENT METHOD
            </Text>
            <Text
              fontWeight="500"
              fontSize="14px"
              color="#33343D"
              lineHeight="18px"
              fontFamily="satoshiBold"
            >
              Direct Debit
            </Text>
          </Box>

          <Box>
            <Text
              opacity="0.81px"
              fontWeight="500"
              fontSize="10px"
              color="#666666"
              lineHeight="18px"
            >
              ACCOUNT NO.
            </Text>
            <Text
              fontWeight="500"
              fontSize="14px"
              color="#33343D"
              lineHeight="18px"
              fontFamily="satoshiBold"
            >
              {loanRequest?.repaymentConfig?.repayment_bank_account_no}
            </Text>
          </Box>

          <Box>
            <Text
              opacity="0.81px"
              fontWeight="500"
              fontSize="10px"
              color="#666666"
              lineHeight="18px"
            >
              BANK NAME
            </Text>
            <Text
              fontWeight="500"
              fontSize="14px"
              color="#33343D"
              lineHeight="18px"
              fontFamily="satoshiBold"
            >
              {loanRequest?.repaymentConfig?.repayment_bank_name}
            </Text>
          </Box>
          <Box>
            <Text
              opacity="0.81px"
              fontWeight="500"
              fontSize="10px"
              color="#666666"
              lineHeight="18px"
              textTransform="uppercase"
            >
              BANK CODE
            </Text>
            <Text
              fontWeight="500"
              fontSize="14px"
              color="#33343D"
              lineHeight="18px"
              fontFamily="satoshiBold"
            >
              {loanRequest?.repaymentConfig?.repayment_bank_code}
            </Text>
          </Box>

          <Box>
            <Text
              opacity="0.81px"
              fontWeight="500"
              fontSize="10px"
              color="#666666"
              lineHeight="18px"
              textTransform="uppercase"
            >
              LOAN TENURE
            </Text>
            <Text
              fontWeight="500"
              fontSize="14px"
              color="#33343D"
              lineHeight="18px"
              fontFamily="satoshiBold"
            >
              {loanRequest?.tenure} Months
            </Text>
          </Box>
        </SimpleGrid>
      )}
    </Box>
  );
};

export default RepaymentDetails;
