import React, { Fragment } from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import { GoArrowDownLeft, GoArrowUpRight } from "react-icons/go";
import moment from "moment";
import { formatMoney } from "../../services/utils/helpersFunc";

const TransactionLog = ({ loanRequest }) => {
  return (
    <Box bg="#FFFFFF" mt="20px" borderRadius="10px" p="10px 20px">
      <Text
        color="#540A45"
        fontWeight="700"
        fontFamily="satoshiBolder"
        fontSize="14px"
      >
        Transaction Log
      </Text>
      {loanRequest?.transactions?.map((transaction, index) => (
        <Fragment key={index}>
          {transaction.type === "credit" ? (
            <Flex marginY="5">
              <GoArrowDownLeft
                size={30}
                color="#44FF22"
                style={{
                  color: "#1BB100",
                  fontSize: "20px",
                  marginTop: "5px",
                  marginRight: "5px",
                  backgroundColor: "#44FF22",
                  padding: "5px",
                  borderRadius: "50px",
                }}
              />

              <Box fontSize="14px" paddingLeft="10px">
                <Text>{transaction?.narration}</Text>
                <Text>
                  ref: {transaction?.reference} -{" "}
                  {moment(transaction.created_at).format("DD MMMM YYYY")}
                </Text>
                <Text>
                  NGN {formatMoney(transaction?.amount)} - {transaction?.tag}
                </Text>
              </Box>
            </Flex>
          ) : (
            <Flex marginY="5">
              <GoArrowUpRight
                size={30}
                color="#B40E78"
                style={{
                  color: "#B40E78",
                  fontSize: "20px",
                  marginTop: "5px",
                  marginRight: "5px",
                  backgroundColor: "#F37A9E",
                  padding: "5px",
                  borderRadius: "50px",
                }}
              />
              <Box fontSize="14px" paddingLeft="10px">
                <Text>{transaction?.narration}</Text>
                <Text>
                  ref: {transaction?.reference} {transaction?.created_at}
                </Text>
                <Text>NGN {transaction?.amount}</Text>
              </Box>
            </Flex>
          )}
        </Fragment>
      ))}

      {loanRequest?.transactions?.length === 0 && (
        <Text marginY={5}>No transaction log available</Text>
      )}
    </Box>
  );
};

export default TransactionLog;
