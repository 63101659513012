import axios from "axios";
import api from "./api";
import { ErrorHandler, objectToFormData } from "./utils/helpersFunc";
import { API_BASE_URL } from "./constants/apiConstants";

export const stringifyFilter = (filter, allowNull) => {
  let filterString = "";
  if (!filter || typeof filter !== "object") {
    return "";
  }
  for (const item in filter) {
    if (
      item &&
      filter[item] !== undefined &&
      filter[item] !== "" &&
      (filter[item] !== null || allowNull)
    ) {
      filterString = filterString ? `${filterString}&` : filterString;
      filterString += `${item}=${filter[item]}`;
    }
  }
  filterString = filterString ? `?${filterString}` : filterString;
  return filterString;
};
export function buildSearchParams(filter) {
  const params = new URLSearchParams();

  if (filter?.searchQuery) {
    params.append("searchQuery", filter.searchQuery);
  }

  if (filter?.page) {
    params.append("page", filter.page.toString());
  }

  if (filter?.limit) {
    params.append("limit", filter.limit.toString() || 20);
  }
  if (filter?.pageLimit) {
    params.append("pageLimit", filter.limit.toString() || "50");
  }
  return params;
}

export const login = async (credentials) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/account/authenticate-admin`,
      credentials
    );
    return response?.data;
  } catch (error) {
    ErrorHandler(error);
  }
};

export const getLoanRequestSummary = async () => {
  try {
    const response = await api.get(`/loans/requests/admin/summary`);
    return response.data;
  } catch (error) {
    ErrorHandler(error);
  }
};

export const adminDeclineLoanDisbursement = async (id) => {
  try {
    const response = await api.post(
      `/instant-loans/requests/${id}/disburse/decline`,
      {}
    );

    return response.data;
  } catch (error) {
    ErrorHandler(error);
  }
};

export const adminApproveLoanDisbursement = async (loanId) => {
  try {
    const response = await api.post(
      `/instant-loans/requests/${loanId}/disburse`
    );
    return response.data;
  } catch (error) {
    ErrorHandler(error);
  }
};

export const getRepaymentConfig = async (id) => {
  try {
    const response = await api.get(
      `/instant-loans/requests/${id}/repayment-config`
    );
    return response.data;
  } catch (error) {
    ErrorHandler(error);
  }
};

export const getRepaymentCardDetails = async (cardId) => {
  try {
    const response = await api.get(`/card/get-card-details/${cardId}`);
    return response.data;
  } catch (error) {
    ErrorHandler(error);
  }
};

export const getInstantLoanRequestDetails = async (requestId) => {
  try {
    const response = await api.get(`/instant-loans/requests/${requestId}`);
    return response.data;
  } catch (error) {
    ErrorHandler(error);
  }
};

export const getPendingLoansDisbursement = async () => {
  try {
    const response = await api.get(
      `/instant-loans/requests/instant/pending-disbursement`
    );
    return response.data;
  } catch (error) {
    ErrorHandler(error);
  }
};

export const getUserDetails = async (userId) => {
  try {
    const response = await api.get(`/user/get-user-identity?userId=${userId}`);
    return response.data;
  } catch (error) {
    ErrorHandler(error);
  }
};

export const adminRejectInstantLoan = async (id, amount) => {
  try {
    const response = await api.post(`/instant-loans/requests/${id}/reject`, {
      amount: amount,
    });

    return response.data;
  } catch (error) {
    ErrorHandler(error);
    // throw error.response.data;
  }
};

export const adminEditInstantLoan = async (id, amount) => {
  try {
    const response = await api.post(`/instant-loans/requests/${id}/edit`, {
      amount,
    });

    return response.data;
  } catch (error) {
    ErrorHandler(error);
    // throw error.response.data;
  }
};

export const adminApproveInstantLoan = async (id) => {
  try {
    const response = await api.post(
      `/instant-loans/requests/${id}/approve`,
      {}
    );

    return response.data;
  } catch (error) {
    ErrorHandler(error);
    // throw error.response.data;
  }
};

export const getAllLoans = async (page) => {
  let APIURL = `/loans/requests?page=${page}`;

  try {
    const response = await api.get(APIURL);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getLoanRequests = async (query) => {
  const params = stringifyFilter(query);
  let APIURL = `/loans/requests${params}`;
  if (localStorage.getItem("role") === "Admin") {
    APIURL = `/loans/offers${params}`;
  }
  try {
    const response = await api.get(APIURL);
    return response?.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getRejectedLoans = async (searchQuery) => {
  //Build the search Query
  const params = stringifyFilter(searchQuery);

  let APIURL = `/loans/declined${params}`;

  try {
    const response = await api.get(APIURL);
    return response?.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getInstantLoanRequests = async (searchQuery) => {
  const params = stringifyFilter(searchQuery);

  let APIURL = `/instant-loans/requests${params}`;
  try {
    const response = await api.get(APIURL);
    return response?.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getTotalInstantLoanCount = async () => {
  let APIURL = `/instant-loans/requests/count`;
  try {
    const response = await api.get(APIURL);
    return response?.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getLoanOffers = async (id) => {
  let APIURL = `/loans/offers/${id}`;
  try {
    const response = await api.get(APIURL);

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getALoanRequest = async (id) => {
  try {
    const response = await api.get(`/loans/requests/${id}`);

    return response.data;
  } catch (error) {
    ErrorHandler(error);
    // throw error.response.data;
  }
};

export const getLoanRequestSLA = async (id) => {
  try {
    const response = await api.get(`/sla/${id}`);

    return response.data;
  } catch (error) {
    ErrorHandler(error);
    // throw error.response.data;
  }
};

export const getGuarantorDetails = async (id) => {
  try {
    const response = await api.get(`/loans/requests/${id}/guarantor`);

    return response?.data;
  } catch (error) {
    // throw error.response.data;
    return;
  }
};

export const SalesApproval = async (id, reason) => {
  try {
    const response = await api.post(`/loans/requests/${id}/approve/sales`, {
      sales_approval: true,
      sales_comment: reason,
    });

    return response.data;
  } catch (error) {
    ErrorHandler(error);
    // throw error.response.data;
  }
};

export const FinalApproval = async (id, reason) => {
  try {
    const response = await api.post(`/loans/offers/${id}/final_approval`, {
      final_approval: true,
    });
    return response.data;
  } catch (error) {
    ErrorHandler(error);
    // throw error.response.data;
  }
};

export const commentLogs = async (loanId, limit) => {
  try {
    const response = await api.get(
      `/loans/requests/${loanId}/logs?pageLimit=${limit}`
    );
    return response.data;
  } catch (error) {
    ErrorHandler(error);
    // throw error.response.data;
  }
};

export const SalesRejection = async (id, reason) => {
  try {
    const response = await api.post(`/loans/requests/${id}/decline/sales`, {
      comment: reason,
    });

    return response.data;
  } catch (error) {
    ErrorHandler(error);
    // throw error.response.data;
  }
};

export const CreditApproval = async (id, formData) => {
  try {
    const response = await api.post(
      `/loans/requests/${id}/approve/credit`,
      formData
    );

    return response.data;
  } catch (error) {
    // throw error.response.data;
    ErrorHandler(error);
  }
};

export const uploadAnyDocument = async (id, formData) => {
  try {
    const response = await api.postForm(
      `/loans/requests/${id}/upload`,
      formData
    );
    return response.data;
  } catch (error) {
    ErrorHandler(error);
    // throw error.response.data;
  }
};

export const StandardComments = async (id, comment) => {
  try {
    const response = await api.post(`/loans/requests/${id}/comment`, {
      comment: comment,
    });

    return response.data;
  } catch (error) {
    ErrorHandler(error);
    // throw error.response.data;
  }
};

export const FinanceApproval = async (id, data) => {
  try {
    const response = await api.post(
      `/loans/requests/${id}/approve/finance`,
      data
    );

    return response.data;
  } catch (error) {
    ErrorHandler(error);
    // throw error.response.data;
  }
};

export const FinanceRejection = async (id, reason) => {
  try {
    const response = await api.post(`/loans/requests/${id}/decline/finance`, {
      finance_approval: false,
      finance_comment: reason,
    });

    return response.data;
  } catch (error) {
    ErrorHandler(error);
    // throw error.response.data;
  }
};

export const CreditRejection = async (id, reason) => {
  try {
    const response = await api.post(`/loans/requests/${id}/decline/credit`, {
      credit_approval: false,
      credit_comment: reason,
    });

    return response.data;
  } catch (error) {
    ErrorHandler(error);
    // throw error.response.data;
  }
};

export const AdminRejection = async (id, reason) => {
  try {
    const response = await api.post(`/loans/requests/${id}/decline/admin`, {
      admin_approval: false,
      admin_comment: reason,
    });

    return response.data;
  } catch (error) {
    ErrorHandler(error);
    // throw error.response.data;
  }
};

export const updateRequestData = async (request_id, data) => {
  const formData = objectToFormData(data);

  const resp = await axios.post(
    `${API_BASE_URL}/loans/requests/${request_id}`,
    formData
  );
  return resp.data;
};

export const pauseSLA = async (id) => {
  try {
    const response = await api.patch(`/sla/${id}/pause`);
    return response.data;
  } catch (error) {
    ErrorHandler(error);
  }
};
