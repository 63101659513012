// eslint-disable-next-line react-hooks/exhaustive-deps

import {
  Box,
  Text,
  Flex,
  HStack,
  Grid,
  GridItem,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useState, useCallback } from "react";
import { BiSearch } from "react-icons/bi";
import { BiChevronDown } from "react-icons/bi";
import { getPendingLoansDisbursement } from "../services/axios";

import { jsPDF } from "jspdf"; //or use your library of choice here
import autoTable from "jspdf-autotable";

import { createMRTColumnHelper } from "material-react-table";
import moment from "moment";
import Loader from "../components/Loader/loader";
import PaginatedPendingDisbursement from "../components/PaginatedPendingDisbursement";

function PendingDisbursement() {
  const [allLoans, setAllLoans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allLoansCopy, setAllLoansCopy] = useState([]);
  const [pagination, setPaginagtion] = useState({
    hasPrev: false,
    hasNext: false,
    currentPage: "1",
  });

  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    limit: 100,
    term: "",
  });

  const columnHelper = createMRTColumnHelper();

  const getPendingLoansDisburse = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getPendingLoansDisbursement(searchQuery);
      setAllLoans(response?.data?.data);
      setAllLoansCopy(response?.data?.data);
      setPaginagtion({
        hasPrev: response?.data?.hasPrev,
        hasNext: response?.data?.hasNext,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [searchQuery]);

  const handleExportRows = (rows) => {
    const doc = new jsPDF();

    let exportedColumns = rows.map(
      ({ account_name, dob, email, phone, amount, first_name, last_name }) => ({
        account_name,
        dob,
        email,
        phone,
        amount,
        first_name,
        last_name,
      })
    );

    const tableHeaders = columns.map((c) => c.header);
    const tableData = exportedColumns.map((row) => Object.values(row));

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    let name = moment().format("LLL") + " loan.pdf";
    doc.save(name);
  };

  const handleInputChange = (e) => {
    setSearchQuery({
      ...searchQuery,
      term: e.target.value,
    });
  };

  const columns = [
    columnHelper.accessor("account_name", {
      header: "Account Name",
      size: 120,
    }),

    columnHelper.accessor("dob", {
      header: "DOB",
      size: 40,
    }),

    columnHelper.accessor("email", {
      header: "Email",
      size: 120,
    }),
    columnHelper.accessor("phone", {
      header: "Phone",
      size: 120,
    }),
    columnHelper.accessor("amount", {
      header: "Amount",
      size: 120,
    }),
    columnHelper.accessor("first_name", {
      header: "First Name",
      size: 120,
    }),
    columnHelper.accessor("last_name", {
      header: "Last Name",
      size: 120,
    }),
  ];

  useEffect(() => {
    getPendingLoansDisburse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPendingLoansDisburse]);

  const searchHandler = () => {
    const search = searchQuery.term;
    const filteredLoans = allLoansCopy.filter((loan) => {
      return (
        loan?.first_name?.toLowerCase().includes(search.toLowerCase()) ||
        loan?.last_name?.toLowerCase().includes(search.toLowerCase()) ||
        loan?.email?.toLowerCase().includes(search.toLowerCase()) ||
        loan?.phone?.toLowerCase().includes(search.toLowerCase())
      );
    });
    setAllLoans(filteredLoans);
  };

  return (
    <Box
      bg="#F8F9FC"
      p={["20px 10px", "20px 10px", "20px 10px 20px 30px"]}
      h="100vh"
      w="100%"
    >
      <Text
        color="#5B2E4F"
        fontSize="18px"
        fontWeight="500"
        fontFamily="satoshiBold"
      >
        Instant Loans
      </Text>

      <Grid templateColumns="repeat(5, 1fr)" gap={6}>
        <GridItem>
          <HStack
            justify="space-between"
            bg="#F8F1F6"
            borderRadius="6px"
            p="20px"
            marginTop="5"
          >
            <Box>
              <Text
                fontSize={["13px", "15px"]}
                color="#33343D"
                fontWeight="500"
                fontFamily="satoshiBold"
              >
                Number of Loans Pending Disbursement
              </Text>
              <Text
                fontSize="21px"
                fontFamily="satoshiBolder"
                color="#5B2E4F"
                lineHeight="28.35px"
              >
                {allLoans?.length}
              </Text>
            </Box>
          </HStack>
        </GridItem>
      </Grid>

      <Box mt="20px">
        <Text
          color="#33343D"
          fontSize="15px"
          fontWeight="700"
          fontFamily="satoshiBolder"
        >
          Loan Requests
        </Text>
        <Flex
          spac
          columns={[2, 2, 4, 4]}
          justify="space-between"
          mt="10px"
          p={["25px 10px", "25px 15px", "25px"]}
          bg="#FFFFFF"
          maxWidth="100%"
        >
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <BiSearch size="20px" color="#666666" />
            </InputLeftElement>
            <Input
              onChange={(e) => {
                handleInputChange(e);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setAllLoans(allLoansCopy);
                  searchHandler();
                }
              }}
              border="1px solid #E4E8F2"
              fontSize="13px"
              fontFamily="satoshiBold"
              color="#666666"
              type="tel"
              placeholder="Search name or enter keyword"
            />
          </InputGroup>

          <Flex
            border="1px solid #5B2E4F"
            bg="#FFFFFF"
            borderRadius="7px"
            marginX="4"
          >
            <Flex p="0 20px" justify="center" align="center">
              <Text
                fontSize="13px"
                color="#5B2E4F"
                fontWeight="500"
                fontFamily="satoshiBold"
                align="center"
              >
                All
              </Text>
            </Flex>
            <Flex
              p="0 10px"
              borderLeft="1px solid #5B2E4F"
              justify="center"
              align="center"
            >
              <BiChevronDown />
            </Flex>
          </Flex>
          <Flex border="1px solid #5B2E4F" bg="#FFFFFF" borderRadius="7px">
            <Flex p="0 15px" justify="center" align="center">
              <Text
                fontSize="13px"
                color="#5B2E4F"
                fontWeight="500"
                fontFamily="satoshiBold"
                align="center"
              >
                7days
              </Text>
            </Flex>
            <Flex
              p="0 10px"
              borderLeft="1px solid #5B2E4F"
              justify="center"
              align="center"
            >
              <BiChevronDown />
            </Flex>
          </Flex>
          <Button
            onClick={() => {
              handleExportRows(allLoans);
            }}
            bg="#5B2E4F"
            color="white"
            fontSize="12px"
            fontWeight="500"
            p="0 30px"
            marginX="4"
          >
            EXPORT
          </Button>
        </Flex>

        {loading && (
          <Box
            display={"flex"}
            justify={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            marginTop={"20"}
          >
            <Loader />
          </Box>
        )}

        {!loading && allLoans?.length === 0 && (
          <Box
            display={"flex"}
            justify={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            marginTop={"20"}
          >
            <Text>
              No Instant Loan Currently Pending Disbursement Available{" "}
            </Text>
          </Box>
        )}

        {allLoans?.length > 0 && (
          <PaginatedPendingDisbursement
            changePage={getPendingLoansDisburse}
            allLoans={allLoans}
            setSearchQuery={setSearchQuery}
            pagination={pagination}
          />
        )}
      </Box>
    </Box>
  );
}

export default PendingDisbursement;
